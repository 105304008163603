<template>
  <div>
    <v-form v-if="loggedIn" @submit.prevent="completeGameWeek">
      <v-row v-for="fixture in getCurrentFixtures" :key="fixture.id">
        <v-col cols="2">
          <v-text-field disabled readonly v-model="fixture.homeTeam" label="Home"></v-text-field>
        </v-col>
        <v-col cols="3" style="display: flex;">
          <v-text-field :disabled="fixture.matchResult" type="number" v-model="fixture.homeGoals" label="Home Goals"></v-text-field>
          <v-text-field :disabled="fixture.matchResult" type="number" v-model="fixture.homePoints" label="Home Points"></v-text-field>
        </v-col>
        <v-col cols="3" style="display: flex;">
          <v-text-field :disabled="fixture.matchResult" type="number" v-model="fixture.awayGoals" label="Away Goals"></v-text-field>
          <v-text-field :disabled="fixture.matchResult" type="number" v-model="fixture.awayPoints" label="Away Points"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field disabled readonly v-model="fixture.awayTeam" label="Away"></v-text-field>
        </v-col>
        <v-col><v-btn @click="updateResult(fixture)" :disabled="fixture.matchResult">Submit result</v-btn></v-col>
          <br/>
        </v-row>
        <v-row>
          <v-spacer/>
          <v-col cols="2">
            <v-btn type="submit" color="primary">End GameWeek</v-btn>
          </v-col>
        </v-row>
     </v-form>
     <div v-else>
      <v-overlay>
        <v-text-field v-model="password"></v-text-field>
        <v-btn @click="enterPassword">Enter</v-btn>
      </v-overlay>
     </div>
  </div>
</template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        password: null,
        loggedIn: false,
        localFixtures: [],
        matchResults: [
          { label: "Home Win", value: "HOME_WIN" },
          { label: "Away Win", value: "AWAY_WIN" },
          { label: "Draw", value: "DRAW" }
        ]
      };
    },
    mounted(){
      this.fetchCurrentFixtures(3); // TODO Hardcoded Comp
      this.fetchWinningMargins('HURLING');
      this.localFixtures = this.getCurrentFixtures;
    },
    methods: {
      ...mapActions(["fetchCurrentFixtures", "fetchTeams", "fetchWinningMargins", "endGameWeek", "updateFixture"]),
      completeGameWeek(){
        let updateData = {fixtureUpdates : [], competitionId: 3, matchday: this.localFixtures[0].matchday};
        this.endGameWeek(updateData);
      },
      updateResult(fixture){
        this.updateFixture(fixture);
      },
      enterPassword(){
        if(this.password == 'briandillons'){
          this.loggedIn = true;
        }
      }
    },
    computed: {
        ...mapGetters(["getCurrentFixtures", "getWinningMargins"])
    },
    watch: {
      getCurrentFixtures(newValue) {
        this.localFixtures = newValue;
      },
      localFixtures(newValue) {
        //this.updateMyState(newValue);
      }
    },
  };
  </script>
  